.metal {
  height: 190px;
}
.slid {
  height: 190px;
}

.sew {
  height: 190px;
}
